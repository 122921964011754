<template>
  <div id="root" :style="colorObject">
    <div class="leeselect-background"></div>
    <div class="container">
      <div class="row mt-2">
        <div class="col-md-6 offset-md-3 col-xxs-12">
          <b-card class="cs-card" no-body>
            <div class="card-body">
              <div class="text-center">
                <img
                  v-if="authThemeConfig.logo_url"
                  :src="authThemeConfig.logo_url"
                  style="width: 150px"
                />
              </div>
              <router-view></router-view>
            </div>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import themeColor from "@/mixins/liff/themeColor";

export default {
  mixins: [themeColor],
  computed: {
    ...mapState("liffCustomerBind", {
      themeConfig: (state) => state.themeConfig,
    }),
    authThemeConfig() {
      if (this.themeConfig) {
        return this.themeConfig;
      } else {
        return {
          logo_url: null,
          background_color: "#186a6b",
          primary_button_color: "#186a6b",
          secondary_button_color: "#d36b2a",
        };
      }
    },
  },
};
</script>

<style>
.leeselect-background {
  background-color: var(--background-color);
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.cs-card {
  border-radius: 1.5rem !important;
}

.float-label {
  position: relative;
  cursor: text;
  font-size: 90%;
  opacity: 1;
  top: 1.3em;
  left: 1rem;
  z-index: 500;
  line-height: 1;
  padding: 0 8px;
  background-color: #ffffff;
}

.primary-button {
  border-radius: 50px;
  border-width: 1.7px;
  border-color: var(--primary-color) !important;
  background-color: #ffffff !important;
  color: var(--primary-color) !important;
}

.primary-button:hover {
  background-color: var(--primary-color) !important;
  color: #ffffff !important;
}

.primary-button:active {
  background-color: var(--primary-color) !important;
  color: #ffffff !important;
}

.secondary-button {
  border-radius: 50px;
  border-width: 1.7px;
  border-color: var(--secondary-color) !important;
  background-color: #ffffff !important;
  color: var(--secondary-color) !important;
}

.secondary-button:hover {
  background-color: var(--secondary-color) !important;
  color: #ffffff !important;
}

.secondary-button:active {
  background-color: var(--secondary-color) !important;
  color: #ffffff !important;
}

a {
  color: #212529;
}
</style>
